// var $ = require('jquery');

module.exports = {
	init: function () {
        var report = document.querySelector('.report');
        var reportButton = document.querySelector('.report .button');
        var closeReport = document.querySelector('.report .content .close-report');
        
        function handleReport(element) {
            element.addEventListener('click', function() {
                if (!report.classList.contains('active')) {
                    report.classList.add('active');
                } else {
                    report.classList.remove('active');
                }
            });
            element.addEventListener('keyup', function(event) {
                if (event.keyCode == 13) {
                    if (!report.classList.contains('active')) {
                        report.classList.add('active');
                    } else {
                        report.classList.remove('active');
                    }
                }
            })
            element.addEventListener('keydown', function(event) {
                if (event.keyCode === 32) {
                    event.preventDefault();
                }
            })
            element.addEventListener('keyup', function(event) {
                if (event.keyCode === 32) {
                    if (!report.classList.contains('active')) {
                        report.classList.add('active');
                    } else {
                        report.classList.remove('active');
                    }
                }
            })
        }
        handleReport(reportButton);
        handleReport(closeReport);
    }
} 
