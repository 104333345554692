let throttle = require('lodash/throttle');

module.exports = {
	init: function () {

        let header = document.querySelector('header');

        // defining a specific instance of debounce to cancel if need be
        let throttle500 = throttle(function() {
            if (window.pageYOffset > 0) {
            header.style.boxShadow = '0 5px 5px rgba(0,0,0,.1)';
            } else {
                header.style.boxShadow = 'none';
            }
        }, 500);

        document.addEventListener('scroll', throttle500);
    }
}