module.exports = {
    init : () => {
        // on document content loaded
        $(window).load(function() {
            setTimeout(() => {
                // fade in the page
                $('.load-wrapper').addClass('fade-in');
            }, 500);
        });
    }
}  