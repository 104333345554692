let throttle = require('lodash/throttle');

module.exports = {
	init: function () {

        const sun = document.querySelector('.homepage-hero .sun');
        let city = null;

        // const motherBoy = lottie.loadAnimation(wpbodymovin.animations[0]);
        // console.log(wpbodymovin.animations);
        // console.log(wpbodymovin.animations[0].id);


        // motherBoy.addEventListener('DOMLoaded', () => {
        //     console.log('motherBoy loaded');
        // });

        // reset city animation when content loaded
        $(window).load(function() {
            bodymovin.goToAndStop(0, true, wpbodymovin.animations[1].animation);
            city = document.querySelector('.city .wpbdmv-animation svg > g');
        });

        setTimeout(function() {
    
            // https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
            function isElementInViewport (el) {
    
                // Special bonus for those using jQuery
                if (typeof jQuery === 'function' && el instanceof jQuery) {
                    el = el[0];
                }
            
                var rect = el.getBoundingClientRect();
            
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
                );
            }
    
            function onVisibilityChange(el, callback) {
                var old_visible;
                return function () {
                    var visible = isElementInViewport(el);
                    if (visible != old_visible) {
                        old_visible = visible;
                        if (typeof callback == 'function') {
                            callback();
                        }
                    }
                }
            }
            
            let handler = onVisibilityChange(sun, function() {
                sun.classList.add('active');
                bodymovin.play(wpbodymovin.animations[1].animation);
            });
            
            // jQuery
            $(window).on('DOMContentLoaded load resize', handler);
            $(window).on('scroll', throttle(handler, 250));
    
        }, 700);
    }
}