var gsap = require('gsap/dist/gsap.min').gsap;
var ScrollTrigger = require('gsap/dist/ScrollTrigger.min');
gsap.registerPlugin(ScrollTrigger);

module.exports = {
    init: function () {
        var butterfly = document.querySelector('.butterfly');
        var ladybug = document.querySelector('.ladybug');
        var timelineLadybug = document.querySelector('.timeline-ladybug');
        if (butterfly) {
            gsap.to('.butterfly', {
                scrollTrigger: {
                    trigger: '.butterfly',
                    toggleActions: 'play pause resume pause',
                    start: 'center center',
                    endTrigger: '.plant',
                    end: 'top 53%',
                    pin: true
                },
                y: -20,
                duration: 1,
                repeat: Infinity,
                yoyo: true,
                yoyoEase: true
            });
        }
        
        if (ladybug) {
            gsap.to('.ladybug', {
                scrollTrigger: {
                    trigger: '.ladybug',
                    toggleActions: 'play pause resume play',
                    start: 'center center',
                    endTrigger: '.plant',
                    end: 'top 49%',
                    pin: true
                },
                y: -20,
                duration: 1,
                repeat: Infinity,
                yoyo: true,
                yoyoEase: true
            });
        }

        if (timelineLadybug) {
            gsap.to('.timeline-ladybug', {
                y: -20,
                duration: 1,
                repeat: Infinity,
                yoyo: true,
                yoyoEase: true
            });
        }
    }
}