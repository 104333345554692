// var $ = require('jquery');
module.exports = {
	init: function () {
		$('.mobile-menu-top > .menu-item > a').on('click', function (evt) {
			evt.preventDefault();
			
			$(this).parent().toggleClass('active');
			$(this).parent().find('.sub-menu').slideToggle('300');
			
		});
	}
}