// var $ = require('jquery');
var countupJqueryPlugin = require('./vendor/jquery.countup');
var styleguide = require('./modules/style-guide.js');
var accordions = require('./modules/accordions.js');
var cards = require('./modules/cards.js');
var menu = require('./modules/menu.js');
var svg = require('./modules/svg.js');
var countup = require('./modules/countup.js');
var sliders = require('./modules/sliders.js');
var animations = require('./modules/animations.js');
var report = require('./modules/report.js');
var mobileMenu = require('./modules/mobile-menu.js');
var headerScrollShadow = require('./modules/headerScrollShadow.js');
var homepageAnimations = require('./modules/homepageAnimations.js');
var fadeIn = require('./modules/fadeIn.js');

// check if homepage for homepageAminations
const homepage = document.querySelector('.homepage-hero');

// Make sure that you init the styleguide first.

$(function(){
    // styleguide.init();
    accordions.init();
    animations.init();
    cards.init();
    menu.init();
    mobileMenu.init();
    svg.init();
    countup.init();
    report.init();
    headerScrollShadow.init();
    fadeIn.init();
    if (homepage != null) {
        homepageAnimations.init();
    }
    if ($('.slider').length > 0) {
        sliders.init();
    }
});
