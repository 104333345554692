// var $ = require('jquery');
module.exports = {
	init: function () {
		var app = new Vue({
			el: '#app',
			data: {
				blocks: document.querySelectorAll('.block')
			}
		})
	}
}