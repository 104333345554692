// var $ = require('jquery');
module.exports = {
	init: function () {
        $('.nav-toggle').on('click', function () {
            $('header').toggleClass('active');
            $('.mobile-nav').toggleClass('active');
            
            if ($('header').hasClass('active')) {
                setTimeout(() => {
                    $('main, footer').hide();
                }, 300);
            } else {
                $('main, footer').show();
            }
        });
	}
}