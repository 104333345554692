// var $ = require('jquery');
// var slick = require('slick-carousel');

module.exports = {
	init: function () {
        var status = $('.paging-info');
        var slickElement1 = $('.slick-slider-style-1');
        var style1Imgs = $('.slick-slider-style-1 .image');
        var slickElement2 = $('.slick-slider-style-2');
        var windowWidth = window.innerWidth;
        window.addEventListener('resize', () => {
          windowWidth = window.innerWidth;
        });
        
        slickElement1.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            // currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            status.text(i + '/' + slick.slideCount);
        });

        // gets the tallest picture in style-1 and makes all picture that height
        slickElement1.on('init beforeChange', function() {
          var imgHeights = [];

          style1Imgs.each(function() {
            imgHeights.push(this.offsetHeight);
          })

          var tallest = Math.max(...imgHeights);


          if (windowWidth > 760) {
            style1Imgs.each(function() {
              this.style.height = tallest + 'px';
            })
          } else {
            style1Imgs.each(function() {
              this.style.height = '350px';
            })
          }
        });

        slickElement1.slick({			
        'slidesToShow': 1, 
        'slidesToScroll': 1,
        'arrows': true,
        'dots': false, 
        'fade': true,
        'centerMode': false,
        'adaptiveHeight': true,
        'variableWidth': false,
        'autoplay': false
        });

        slickElement2.slick({			
            'slidesToShow': 4, 
            'slidesToScroll': 4,
            'arrows': false,
            'dots': true, 
            'fade': false,
            'centerMode': false,
            'adaptiveHeight': true,
            'variableWidth': false,
            'autoplay': false,
            'responsive': [
                {
                  breakpoint: 650,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                  }
                }
              ]
            });
    } 
} 